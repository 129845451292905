// Variables
$isi-background-color: rgba(255,255,255,0.95);
$isi-font-family: Arial,Helvetica,sans-serif;
$isi-font-color: #000;
$isi-font-size: 15px;
$isi-line-height: 1.2em;
$isi-title-font-family: Arial,Helvetica,sans-serif;
$isi-title-font-color: #008c98;
$isi-title-font-size: 16px;
$container-max-width: 1170px;

// Main wrapper
.isi-main {

    width: 100%;
    background-color: $isi-background-color;
    padding: 0px;

    box-sizing: border-box;
    -moz-box-sizing: border-box;

    @media screen and (min-width: 781px) {
        padding: 12px 40px;
    }

    &__container {

        display: block;
        max-width: $container-max-width;
        margin: 0px auto;

        @media screen and (min-width: 781px) {
            //display: flex;
        }
        
        // Main titles
        .pane-title {

            p {

                font-family: $isi-font-family;
                color: $isi-font-color;
                font-size: $isi-font-size;
                line-height: $isi-line-height;
                font-weight: bold;

            }
            
        }

        p {
            font-family: $isi-font-family;
            color: $isi-font-color;
            font-size: $isi-font-size;
            line-height: $isi-line-height;
        }

        ul {
            margin: 0px;
            padding: 0px 0px 0px 10px;

            li {
                font-family: $isi-font-family;
                color: $isi-font-color;
                font-size: $isi-font-size;
                line-height: $isi-line-height;
                margin-bottom: 1em;
                position: relative;
                list-style-type: none;
                text-align: left;

                &::before {
                    content: "\2022";
                    color: #e3be11;
                    margin-right: 4px;
                    position: absolute;
                    margin-left: -10px;
                    font-size: 12px;
                }

                ul {
                    margin-top: 5px;

                    li {
                        margin-bottom: 0.3em;

                        &::before {
                            content: "\25E6";
                            margin-right: 2px;
                            color: #000;
                            font-size: 14px;
                        }
                    }
                }
            }

            
        }

        #indication {

            @media screen and (min-width: 781px) {
                width: 34%;
                order: 2;
                padding: 30px;
                border-left: 2px solid #008c98;

                // Fix until JNJ corrects HTML
                float: right;
            }
        }
    
        #isi {
            @media screen and (min-width: 781px) {
                width: 64%;
                margin-right: 2%;
            }
        }

        table {

            display: block;
            border: none;
            box-shadow: none;
            border-collapse: unset;

            @media screen and (min-width: 781px) {
                width: 64%;
            }

            td {
                padding: 0px 10px;
                font-weight: 400;

                ul li{

                    margin-bottom: 0.3em;

                    &::before {
                        content: "\25E6";
                        margin-right: 2px;
                        color: #000;
                        font-size: 14px;
                    }

                }
            }
        }

    }

    &__expand {

        position: absolute;
        top: 4px;
        right: 10px;
        cursor: pointer;
        font-size: 1.4em;
        color: #008c98;

        @media screen and (min-width: 781px) {
            top: 15px;
            right: 35px;
            font-size: 1.4em;
        }
    }

    a[href^="tel:"] {
        color: $isi-font-color;
        text-decoration: none;
    }

}

// Pinned ISI
#pinned-isi {

    max-height: 25vh;
    position: fixed;
    left: 0px;
    bottom: 0px;
    z-index: 50;
    display: none;
    transition: max-height 0.4s ease;
    max-width: $container-max-width;
    left: 50%;
    transform: translate(-50%, 0);
    -webkit-box-shadow: 0 0 60px rgba(0,0,0,0.7);
    box-shadow: 0 0 60px rgba(0,0,0,0.7);

    &.show {
        display: block;
    }

    &.expand {
        max-height: 75vh;
        @media screen and (min-width: 781px) {
            overflow: auto;
        }
    }

    .isi-panel-pane {

        position: relative;
        max-height: 12.5vh;
        overflow: hidden;
        transition: max-height 0.4s ease;
        border-top: 1px solid #999;
        padding: 0px 15px;
    
        @media screen and (min-width: 781px) {
            max-height: none;
            border: none;
            padding: 0px;
        }
    
        &.expand {
            max-height: 62.5vh;

            .pane-content {
                height: auto;
                max-height: 55vh;
                overflow: auto;

                @media screen and (min-width: 781px) {
                    height: auto;
                    overflow: hidden;
                }
            }
        }

        .pane-content {
            p:first-child {
                margin-top: 0px;
            }
        }

        &#indication {

            @media screen and (min-width: 781px) {
                width: 34%;
                order: 2;
                padding: 30px;
                border-left: 2px solid #008c98;
                margin-top: 20px;

                // Fix until JNJ corrects HTML
                float: right;
            }
        }
    }
}

// Static ISI
#janssen-isi-content-wrapper {

    padding: 10px 20px;

    @media screen and (min-width: 781px) {
        padding: 30px 23px;
    }
}

// Expand-Close CTAs
#expand-dsk-btn {
    display: none;
    @media screen and (min-width: 781px) {
        display: block;
    }
}

#expand-mob-wis-btn, #expand-mob-isi-btn {
    display: block;
    @media screen and (min-width: 781px) {
        display: none;
    }
}

// ISI Scrollbar Styling
#pinned-isi::-webkit-scrollbar {
    width: 8px;
}

#pinned-isi::-webkit-scrollbar-track {
    background-color: $isi-background-color;
}

#pinned-isi::-webkit-scrollbar-thumb {
    background-color: #008c98;
    border-radius: 5px;
}

// Prevent Body Scroll on Expanded ISI
body.lock {
    overflow: hidden;
    height: 100%;
    position: relative;
    touch-action: none;
    -ms-touch-action: none;
}

// Custom Content
.importantsaf {
    font-size: 24px !important;
    color: #07545b !important;
}

.whoshouldnot, .donottakeedu, .beforetaking, .whatshouldit, .whatarethepo, .edurantcanca, .howshouldita, .whatiseduran {
    color: $isi-title-font-color !important;
    font-size: $isi-title-font-size !important;
}

.ifyougetaras {
    padding-left: 10px;
}