@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';
@import 'components/isi';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;
/* ====================================================
    Global
   ==================================================== */
   
body {
	font-size: 18px;
	line-height: 1.1em;
	overflow-x: hidden !important;
}
p, li {
	line-height: 1.2em;
}

li {
	margin-bottom: 1em;
}

ul ul {
	list-style-type: circle;
}
ul.number{
	list-style: decimal !important;
	li{
		margin-left: 15px;
		&::before{
		display: none;
	}
	}
}
ol{
	color: $primary-color;
	font-weight: bold;
	list-style-position: inside;
	margin-left: 0;
	span,p{
		font-weight: 400;
		color: #000;
		margin-top: 16px;
	}
	li{
		position: relative;
		p:first-child{
			display: inline;
		}
	  }
	  ul{
		margin-top: 16px;
	  }
}

h1 {
	font-family: 'Arial', sans-serif;
	color: $secondary-color;
	font-weight: 700;
	line-height: 1.1em;
	font-size: 30px;
	margin-bottom: 25px;
}

h2 {
	color: $primary-color;
	font-weight: bold;
	line-height: 1.1em;
	font-size: 24px;

	&.title{
		font-family: 'Arial', sans-serif;
		font-weight: 700;
		font-size: 26px;
	}
}
h3{
	font-weight:bold;
	font-size: 20px;
	color:#69B8BF;
}
h4{
	color: $secondary-color;
	font-weight: 700;
	font-size: 20px;
}
h5{
	font-family: 'Arial', sans-serif;
	color: $secondary-color;
	font-weight: 700;
	font-size: 17px;
}
h6{
	color: $primary-color;
	font-weight: bold;
	font-size: 16px;
}

a {
	color: $primary-color;
	text-decoration: underline;

	&:hover{
		color: $secondary-color;
	}
}

ul {
	color: $secondary-color;

	span {
		color: $black;
	}
}

sup {
	line-height:0;
	top:0;
	font-size:60%;
	vertical-align: super;
}

strong {
	font-weight: 700;
}

small {
	line-height: 0.5em;
}
.inline{
	display: inline;
}
.inline-block{
	display:inline-block;
}
.no-wrap{
	white-space: nowrap;
}

.faqs-box{
	padding: 20px 0;
	border-top:1px solid #D5D5D5;
	&:first-of-type{
		border-top:2px solid #008C98;
	}
}
.box{
	margin: auto;
	padding: 0 22px;
	@media screen and (max-width: 1024px){
		//padding-right: 0.225rem !important;
		padding: 0;
	}
}
.box-container,.isi-section{
	font-size: 15px;
	background-color: rgba(255,255,255,0.95);
	padding-top: 30px;
	padding-bottom: 30px;
	padding: 30px 23px;
    max-width: 100%;
	@media screen and (max-width: 1024px){
		padding: 30px 0;
	}
	ul{
		color:#000;
		list-style: none;
		margin-left: 0;
		li{
			position: relative;
			margin-left: 10px;
			&::before {
				content: "\2022"; 
				color: #E3BE11;	
				position: absolute;	
				margin-left: -10px;	
				font-size: 12px;	
			  }
		}
	
	}
	ul.dash{
		list-style: none;
		color: #000;
		margin-top: 5px;
		li{
			margin-bottom: .3em;
			&::before{
				content: "\2011";
				color: #000;
				font-size: 16px;
			}
		}
		
		
	}
	ul.bull-empty{
		list-style: none;
		color: #000;
		margin-top: 5px;
		li{
			margin-bottom: .3em;
			&::before{
				content: "\25E6";
				color: #000;
				font-size: 14px;
			}
		}
		
		
	}
	.mb{
		margin-bottom: .3em;
	}	  
	.mb-08{
		margin-bottom: .8em;
	}
	.mb-4{
		margin-bottom: 4em;
	}
		  span.panel-action{
			color: #69B8BF;
			text-decoration:underline;
			text-decoration-style: dotted;
			cursor: pointer;
			}
}
.bold,
.strong {
	font-weight: bold;
}
.alter-font {
	font-size: 14px;
}
.small-caps{
	font-variant: small-caps;
}
.container-spacing {
	padding-top: 23px;
	padding-bottom: 23px;
}
.watermark {
	background-image: url('../img/logo_watermark.svg'); 
	background-repeat: no-repeat; 
	background-position: center center;
	font-size: 15px;
}
strong.fcolor{
	font-size: 16px;
}
.fcolor{
	color:$primary-color;	
}
.fcolor-gray{
	color: #666;
}
.fcolor-blue{
	color: $secondary-color;
}

.color-carepath{
	color:#003479;
}

.footnote{
	font-size: 14px;

	p{
		text-indent: -5px;
		margin-left: 5px;
		margin-bottom: 10px;
	}
}

.ul-sub {
	margin-top: 1em;
	p.p-text {
		color: $black;
		font-weight: normal;
	}
}

.vAlign .cell{
	margin-top: auto;
	margin-bottom: auto;
}

.mBottom0{
	margin-bottom: 0;
}

.site_map {
	li h3 a {
		color: $primary-color;
		text-decoration: none;
	}

	li a {
		color:#000;
	}

}

.thumbnail {
	border: 4px solid #69B8BF;
}

.nolinkline {
	text-decoration: none;
}


/* DROPDOWN CUSTOM */
.dropdown-content{
	position: absolute;
    background-color: #69B8BF;
    font-size: 12px;
    max-width: 300px;
    visibility: hidden;
    z-index: 2;
    color: #FFF;
    padding: 1rem;
}
.dropdown-panel.active .dropdown-content{
	visibility: visible;
	&.arrow-left::before{
    content: "";
			display: block;
			position: absolute;
			top: -8px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 8px 8px 8px;
			border-color: transparent transparent #69B8BF transparent;
      left: 10px;
  }
  &.arrow-right::before{
    content: "";
			display: block;
			position: absolute;
			top: -8px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 8px 8px 8px;
			border-color: transparent transparent #69B8BF transparent;
      right: 10px;
  }
  p{
	  margin: 0;
  }
  span{
	  color: #FFF;
  }
}

/* ====================================================
    Social Sharing Menu
   ==================================================== */
header .top-nav-area .top-nav .menu a.social-share {
	background-color: #fff;
	color:$primary-color;
	font-weight: bold;
	border-radius: 20px;
	text-transform: uppercase;
	font-size: 13px;
	padding: 3px 9px;
	margin-top: -2px;

	.share-icon{
		width: 17px;
	}
	
}

.dropdown.menu > li.is-dropdown-submenu-parent > a.social-share::after {
	border: none;
}
header .top-nav-area .top-nav .menu a.social-share::after{
	content:'';
	margin-left: 0;
}

header .top-nav-area .top-nav .menu a.social-share::after li a:after {
	content:'';
}

.social-share-area{
	position: relative;
}

.social-share-menu.is-dropdown-submenu {
	border: none;
	background-color:#fff;
	/*min-width: 171px;*/
	min-width: 180px;
	margin-top: 20px;
	text-align: left !important;

	&::after {
		position: absolute;
	    left: 75%;
	    top: -14px;
	    width: 0;
	    height: 0;
	    content: '';
	    border-left: 15px solid transparent;
	    border-right: 15px solid transparent;
	    border-bottom: 15px solid #fff;
	}
}

.social-share-menu.is-dropdown-submenu > li {
	width: auto;
	/*display: inline-block;*/
	text-align: left;
	/*border-right: 1px solid #ccc;*/
	padding: 9px 5px;
	float: left;

	&:last-child {
		border-right: none;


	}

	a {
		padding: 0!important;
		margin: 0!important;
		img {
			width: 22px;
			/*text-align: left;
			align-self: left;*/
		}
		&.facebook-share-button {
			width: 11px;
		}
		&.phone-share-button {
			width: 14px;
		}
		&.email-share-button {
			width: 24px;
		}
	}
	a:hover, a:focus {
		text-decoration: none !important;
		color: #ffffff;
	}
	&.first-sub {
		/*left: 0!important;
		right: auto !important;*/
	}
}

// .accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after{
// 	border: none;
// }

// @media screen and (max-width: 39.9375em) {
// 	.social-share-area .accordion-menu li {
// 		width: auto;
// 	}
// }

// /* Medium only */
// @media screen and (min-width: 40em) and (max-width: 63.9375em) {
// 	.dropdown.menu > li.opens-right > .is-dropdown-submenu {
// 		left: -134px;
// 	}
// }


/* ====================================================
    Header - desktop
   ==================================================== */
header {
	background-color: rgba(0,0,0, 0.4);
	position: absolute;
	top: 0;
	right:0;
	left:0;
	z-index: 10;
	padding-top: 10px;
	padding-bottom: 10px;
	width: 100%;
	max-width: 100%;
	margin: 0 auto;
	padding-right: 0;
    padding-left: 0;
	// overflow-x: hidden;
	ul {
		list-style:none;
		color: #fff;
		margin:0;

		li{
			display: inline-block;
			margin-bottom:0;

			a {
				color: #fff;
				text-decoration: none;

				&:hover{
					color: #FED204;
				}
				&:focus{
					color: #fff;
				}
			}

		}
	}

	.top-nav-area {
		font-size: 15px;
		color: #69B8BF;

		p{
			margin-bottom:5px;
		}

		.top-nav {
			text-align:right;
			color: #fff;

			.menu a, .menu .button{
				padding: 0 5px;

				&::after {
					content:"|";
					margin-left: 9px;

				}
				
			}
			.menu a.extHCP::after{
					content: '';
				}		
		}
	}
	


	.main-nav {
		padding-top:7px;

		ul {
			text-align: center;
			font-size: 18px;
			text-transform: uppercase;
			line-height: 1.1em;	
			float: right;
			//float: left;

			li a{

                &:hover {
                    color:#FED204;
                }
                &:focus{
                    color:#fff;
                }
                &.active{
                    color:#FED204;
                }
             }
             li:hover > a {
                    color:#FED204;
                }	
		}

		.is-dropdown-submenu{
			border:none;
			background-color: rgba($primary-color,0.85);
			font-size:16px;
			text-transform: capitalize;
			li{
				border-bottom: 1px solid #fff;
				&:last-child {
					border-bottom: none;
				}
			}
		}

		.dropdown.menu > li > a, .dropdown.menu > li.is-dropdown-submenu-parent > a{
			//padding: 0.7rem 2.5rem;
			padding: 0.7rem 2.8rem;
		}

		.dropdown.menu > li.opens-right > .is-dropdown-submenu{
			//top: 125%;
			top: 117%;
			
		}

		.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
			display:none;
		}

		.dropdown.menu > li.is-active > a {
			color:#69B8BF;
		}

		.is-dropdown-submenu.first-sub > li {
			width: auto;
			display: inline-block;
			border-bottom: 0;
		}

/*		.is-dropdown-submenu.first-sub {
			text-align: center;
			position: absolute;
			left: 0;
			//min-width: 1200px;
			min-width: 73rem;
			//margin-left: -307px;
				&::after {
					bottom: 100%;
					left: 400px;
					border: solid transparent;
					content: " ";
					height: 0;
					width: 0;
					position: absolute;
					pointer-events: none;
					border-color: rgba(44, 141, 152, 0);
					border-bottom-color: #2c8d98;
					border-width: 10px;
					margin-left: -10px;
				}
			&.menu-about {
				//margin-left: -268px;
				&::after {
					//left: 365px;
				}
			}
			&.menu-hiv {
				//margin-left: -466px; 
				&::after {
					//left: 580px;
				}
			}
			&.menu-imp {
				//margin-left: -897px;
				&::after {
					//left: 1005px;
				}
			}
		}*/
	}

	.mega-menu-container {
		ul {
			
			li a{

                &:hover {
                    color:#FED204;
                }
                &:focus{
                    color:#fff;
                }
                &.active{
                    color:#FED204;
                }
             }
             li:hover > a {
                    color:#FED204;
                }	
		}
	}
	
	&.hcp {
		.top-nav-area {
			.top-nav {
				.menu a, .menu .button{
					//padding: 0 5px;
					padding: 0;

					&::after {
						content:"|";
						//margin-left: 9px;
						margin-right: 5px;
						margin-left: 5px;
					}
					&.social-share {
						padding: 3px 9px;
						&::after {
							content: '';
							margin-right: 0px;
							margin-left: 0px;
						}
					}
				}		
			}
		}
		.main-nav,
		.mega-menu-container {
			//padding-top: 7px;
			ul {
				font-size: 15px;

			}

			.dropdown.menu > li.opens-right > .is-dropdown-submenu{
				//top: 138%;
				top: 119%;

			}

			.dropdown.menu > li > a, .dropdown.menu > li.is-dropdown-submenu-parent > a{
				//padding: 0.7rem 1.0rem;
				padding: 0.7rem 0.7rem !important;
			}
			.dropdown.menu > li:last-child > a, .dropdown.menu > li:last-child.is-dropdown-submenu-parent > a{
				padding-right: 0;
			}
			.dropdown.menu > li:first-child > a, .dropdown.menu > li:first-child.is-dropdown-submenu-parent > a{
				padding-left: 0;
			}

			/*.is-dropdown-submenu.first-sub {
				&.menu-act {
					margin-left: -343px;	
					&::after {
						left: 375px;
					}
				}
				&.menu-saf {
					margin-left: -422px;
					&::after {
						left: 490px;
					}
				}
			}*/

			.dropdown.menu > li.menu-text {
	    		padding: 0!important;
	    		a {
	    			padding: 0 6rem 0 4rem !important;
	    			@media screen and (min-width: 1024px) and (max-width: 1166px) {
				    	//padding-right: 0.225rem !important;
				    	padding-left: 0.225rem !important;
				    }
	    		}

	    	}
		}
	}
}

header > .grid-container:first-child {
	padding-right: 4.2rem;
    padding-left: 4.2rem;
    @media screen and (min-width: 1024px) and (max-width: 1166px) {
    	padding-right: 0.225rem;
    	padding-left: 0.225rem;
    }
    .main-nav-area {
    	ul {
    		@media screen and (min-width: 1024px) and (max-width: 1166px) {
		    	float: right;
		    }
    	}
    }
}
header .grid-container.mega-menu-container {
	padding-right: 0;
    padding-left: 0;
}


/* Small & Medium only */
@media screen and (min-width: 0em) and (max-width: 63.9375em) {
	header > .grid-container,
	header.hcp > .grid-container,
	header > .grid-container:first-child {
		padding-right: 0.625rem;
    	padding-left: 0.625rem;
	}
	header .main-nav ul {
		float: none;
		text-align: left;
	}
}

/* Large only */
@media screen and (min-width: 64em) and (max-width: 1115px) {
	header .main-nav .dropdown.menu > li > a, header .main-nav .dropdown.menu > li.is-dropdown-submenu-parent > a{
		//padding: 0.7rem 1.9rem;
		//padding: 0.7rem;
	}

	
}


/* ====================================================
    Header - mobile
   ==================================================== */

/* Small & Medium only */
@media screen and (min-width: 0em) and (max-width: 63.9375em) {
	.isi-section{
		padding: 0 0 !important;
		.isiBox{
			padding: 0;
			margin: 0 !important;
		}
	}
	.box{
		padding: 0;
	}
	.box-container{
		padding: 0;
	}
	.mini-panel-isi{
		border-left: none !important;
		padding: 0 !important;
		margin-top: 0 !important;
	}

	.logo {
		width: 120px;
	}
	.top-nav {
		margin-bottom: 10px;
	}
	.main-nav-area {
		.mobile-nav {
			float: right;
		}

		.main-nav {
			background-color: $primary-color;
			margin-left:0;
			margin-right: 0;
			width: 100%;
			margin-top: 15px;
			padding-top: 0;

			.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
				border-color: #fff transparent transparent;
			}

			ul {
				text-align: left;

				ul li {
					background-color:#69B8BF;
					text-transform: capitalize;

					a:hover, a.active {
						color:$primary-color;
					}


				}

			}

			.extra-nav {
				background-color: #2f2f2f;
				text-transform: capitalize;
				font-size: 15px;
			}


		}
	}

}


/* ====================================================
    Hero
   ==================================================== */
  .hero.cont{
	  @media screen and (max-width:980px){
		
		background-position: 60% center !important;
	}
  } 
.hero {
	color:#fff;
	padding-top: 156px;
	margin-bottom: 40px;
	z-index: 1;
	position: relative;
	
	h1 {
		font-weight:400;
		color:#88E3EB;
		font-size: 35px;
		line-height: 1.1em;
		margin-top: 98px;
		text-shadow: 0px 2px 12px #000;
		margin-bottom: 0.8rem;
		font-family: 'Roboto Slab', sans-serif;
		a {
			text-decoration: none;
			color:#FED204;
		}
	}

	.footnote{
		text-shadow: 0px 2px 12px #000;
	}

	a {
		color:#fff;
	}

	.button {
		font-size: 18px;
		// box-shadow: 0px 2px 16px #000;
		color: #008C98;
		&.small {
			font-size: 15px;
			padding: 0.5em 1.2em;
		}
		&:hover{
			background-color: #e9bf03;
		}
		&:focus{
			background-color: #e9bf03;
		}
	}

	ul span {
		color:#fff;
	}

	&.sub {
		margin-bottom:0px;
		padding-top: 180px;
		min-height: 600px;

		h2 {
			font-weight:400;
			color:#fff;
			font-family: 'Roboto Slab', sans-serif;
			margin-top: 115px;
			font-size: 35px;
			line-height: 1.1em;
			text-shadow: 0px 2px 12px #000;
		}

		&.three-boxes {
			padding-top: 145px;
		}
	}
}

.trans-box {
	background-color: rgba(0,140,152, 0.67);

	h4 {
		color:#FED204;
		font-family: 'Roboto Slab', sans-serif;
		margin-bottom: 0;
		font-weight: 700;
	}

	img {
		margin-bottom: 5px;
	}

	&.box1 {
		font-size: 14px;
		padding: 30px 34px;
		float: right;
		.dropdown-pane {
			background-color: #FFF;
			font-size: 12px;
			span{
				color:$primary-color !important;
			}
			p{
				color:$secondary-color;
				margin: 0;	
			} 
			 &::after {
			content: "";
			display: block;
			position: absolute;
			top: -8px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 8px 8px 8px;
			border-color: transparent transparent #FFF transparent;
			  }
		  }
		h2 {
			color:#fff;
			font-family: 'Arial', sans-serif;
			font-weight: 400;
		}
		h4 {
			font-weight: 400;
		}
		ul li{
			color: #FFF;
			ul{
				margin-top: 10px;
			}
		}
		span.panel-action{
			text-decoration:underline;
			text-decoration-style: dotted;
			}
		
	}
	&.box4{
		text-align: left;
		padding: 50px 42px 58px;
		// margin-top: 119px;
		@media screen and (max-width:639px){
			margin-top: 50px;
		}
		h1{
			margin: 0 0 6px 0;
			text-shadow: none;
		}
	}
	
	&.box2 {
		padding: 20px 30px 20px;
		position:relative;
		text-align: center;
		font-size: 15px;

		.btn-bottom {
			position: absolute;
			bottom:-30px;
			right: 0;
			left: 0;
			width: 100%;

			.button.small {
				box-shadow: none;
			}
			
		}
	}

	&.box3{
		padding: 15px 10px 10px;
		text-align:center;

		.button.small {
			box-shadow: none;
			margin-bottom: -29px;
		}
	}
}
.box4{
	text-align: left;
	padding: 50px 42px 58px;
	position: relative;
	h1{
		margin: 0 0 6px 0;
		text-shadow: none;
	}
	h1,p,span,a{
		position: relative;
	}
}
.principal-link{
	position: absolute !important;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
}
.hcp {
	&.hero {
		padding-top: 182px;

		h1 {
			margin-top: 138px;
		}

		.trans-box.box1 h2 {
			font-size: 22px;
		}
	}
}


/* Small only */
@media screen and (max-width: 39.9375em) {
	.hero-home{
		background-color: #000;
	}

	.hero {
		margin-bottom: 0px;
		padding-top: 150px;
		

		h1 {
			margin-top: 0;
		}

		&.sub h2{
			margin-top: 10px;
		}
	}
	.trans-box.box1 {
		max-width: 100%;
		margin-top: 10px;
	}
	.trans-box.box2 {
		margin-bottom: 20px;

		.btn-bottom {
			position: inherit;
		}
	} 

	.hcp {
		&.hero {

			h1 {
				margin-top: 0px;
			}
		}
	}


	body {
		&.page-about-intelence,
		&.page-dosing,
		&.page-drug-interactions,
		&.page-side-effects,
		&.page-duet-trials-design,
		&.page-immunologic-response,
		&.page-k103n-nnrti-mutations,
		&.page-virologic-response {
			.hero {
				background-position: -260px 0 !important;
			}
		}
		&.page-administration-options {
			.hero {
				background-position: -380px 0 !important;
			}
		}
		&.page-important-safety-info,
		&.page-important-safety-info-hcp,
		&.page-legal-notice,
		&.page-legal-notice-hcp,
		&.page-med-info-center-hcp,
		&.page-privacy-policy,
		&.page-privacy-policy-hcp,
		&.page-site-map,
		&.page-site-map-hcp,
		&.page-professional-resources-hcp,
		&.page-references-hcp,
		&.page-contact-us {
			.hero {
				background-position: -1100px 0 !important;
			}
		}
	}

}


.video-embed {
	position: relative;
	height: auto;
	margin-bottom: 0rem;
	overflow: hidden;
	max-width: 83em;
    margin: 0 auto;
}
.video-embed video{
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	height: 700px;

}
.video-embed .hero-img{
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	height: auto;
}

.hero-hcp .video-embed video{
	height: 700px;
}


/* ====================================================
    Boxes
   ==================================================== */
.gray-box {
	background-color:#E0E0E0;
	text-align:center;
	font-size: 15px;
	padding: 30px 10px 0px;

	img {
		margin-bottom: 15px;
	}

	.button.small {
		margin-bottom: -15px;
		&:hover, &:focus{
			background-color: #ad2b2b;
		}
	}
}

.copay{
	background-image: url('../img/img-copay.jpg'); 
	background-repeat: no-repeat; 
	background-position: top center;
	background-size: cover;
	padding-top: 65px;
	padding-bottom: 50px;
	color:#fff;
	@media screen and (max-width: 39.9375em) {
		background-position: -1100px 0 !important;
	}
	h3{
		font-size: 28px;
		font-weight: 400;
		font-family: 'Arial', sans-serif;

		a {
			text-decoration: none;
			color:#69B8BF;
		}
	}

	.footnote{
		font-size: 15px;
	}

	a {
		color:#fff;
	}
}



.duet-trials {
	color: $primary-color;
	font-weight: 700;
	font-size: 40px;
	font-family: 'Arial', sans-serif;
	text-align: center;

	.tbox {
		background-color: $primary-color;
	}

	.gbox{
		background-color: #666;
	}

	p {
		margin-bottom:0;

		&.big {
			font-size: 26px;
			font-weight: bold;
		}
	}

	.duet-trials-box {
		font-family: Arial, sans-serif;
		font-size: 18px;
		color:#fff;
		padding: 20px;
		font-weight: normal;
		height: 100%;
		background-image: url('../img/logo_watermark_white.svg'); 
		background-position: top center;
		background-repeat: no-repeat;
	}
}

/* ====================================================
    Tables
   ==================================================== */
table {
	font-weight: bold;
	border-bottom: 5px solid $primary-color;
	border-top: 5px solid $primary-color;
	box-shadow: 0px 3px 39px rgba(0,0,0,0.30);
	margin-top: 10px;

	thead {
		font-family: 'Arial', sans-serif;
		font-weight: 700;
		border-bottom: 2px solid $primary-color;

		th {
			text-align: center;
		}
	}

	tbody{
		background-color: inherit;
	}

	tbody th, tbody td{
		padding: 0.8rem 0.625rem 0.925rem
	}

	td + td {
		font-size: 25px;
		text-align: center;
	}

	&.table-nostyle{
		margin:0;
		border: none;
		box-shadow: none;

		tbody {
			border: none;

			td {
				padding-top: 0.4rem;
				padding-bottom: 0.525rem;
			}

			tr:nth-child(even){
				background-color: inherit;
			}

		}

	}
}

table.hover:not(.unstriped) table.table-nostyle tr:nth-of-type(even):hover {
	background-color: inherit;
}

.duet-trials table.disorders {
	font-size: 28px;
	color:$secondary-color;

	tr{
		border-bottom: 1px solid $primary-color;
	}

	td {
		vertical-align: top;
		font-family: Arial, sans-serif;
	}

	td + td {
		font-size: 18px;
		text-align: left;
		font-weight: normal;
		line-height: 1.3em;
		color:#000;
	}
	tbody tr:nth-child(even){
		background-color:inherit;
	}
}

/* ====================================================
    Graphs
   ==================================================== */
.graph-area {
	background-color:#fff;
	box-shadow: 0px 3px 35px rgba(0,0,0,0.30);
	padding: 30px 40px;
	text-align: center;
	margin-bottom: 2rem;
}


/* ====================================================
    Tabs
   ==================================================== */
.tab-area {
	background-color:#fff;
	box-shadow: 0px 3px 35px rgba(0,0,0,0.30);

	.tabs {
		background: inherit;
		border: none;

		li {
			margin-bottom: 0;
		}

		.tabs-title > a {
			text-decoration: none;
			font-size: 16px;
		}

		.tabs-title > a:focus, .tabs-title > a[aria-selected='true']{
			background: $primary-color;
			color: #fff;
		}
	}

	.tabs-content {
		border: none;
		background: inherit;

		.tabs-panel{
			padding: 2rem;
		}

		table {
			box-shadow: none;
		}
	}
}


/* ====================================================
    SWF video
   ==================================================== */
.responsive-embed, .flex-video {
	padding-bottom: 57%;
}

/* ====================================================
    Buttons
   ==================================================== */
.button {
    text-decoration: none;
    text-transform:uppercase;
	background: #FED204;
    &:after {
    	content: '\25b6 \fe0e';
    	color: #008C98;
    	// background-color: #FED204;
    	margin-left: 10px;
    }
}

.button.secondary {
    background-color: $primary-color;
	color: #fff; 
}
.button.secondary:hover, .button.secondary:focus {
  background-color: #69B8BF;
  color: #fff; }
.button-red{
	background-color:#CC3333;
	&:after {
    	content: '\25b6 \fe0e';
    	color: #fff;
    	margin-left: 10px;
    }
}

/* ====================================================
    Reveal
   ==================================================== */
.reveal {
	padding: 3rem;
}

.reveal.large {
	max-width: 50em;
}

/* ====================================================
    ISI Section
   ==================================================== */
   .mini-panel-isi{
	border-left: 2px solid $primary-color;
	padding: 30px;
	margin-top: 20px;
   }
  .isi-border{
	   height: 2px;
	   background-color: $primary-color;
   }
.isi-section {
	font-size: 15px;
	background-color: rgba(255,255,255,0.95);
	padding-top: 30px;
	padding-bottom: 30px;
	padding: 30px 23px;
    max-width: 100%;

	h1,h2,h3,h4,h5,h6{
		font-family: Arial, Helvetica, sans-serif;
	}
	h2{
		color: $secondary-color;
	}
	.content_box{
		color:#000;
	}
}
.isiBox{
	margin: auto;
	padding: 0 22px;
	ul{
		color:#000;
		list-style: none;
		margin-left: 0;
		li::before {
			content: "\2022"; 
			color: #E3BE11;
			margin-right: 4px;
	

		  }
	}
	
	ul li ul.dash{
		list-style: none;
		color: #000;
		margin-top: 5px;
			li::before{
				content: "\2011";
				margin-right: 2px;
				color: #000;
			
			}
		  
	}

	ul li ul.bull-empty{
		list-style: none;
		color: #000;
		margin-top: 5px;
			li::before{
				content: "\25E6";
				margin-right: 2px;
				color: #000;
			
			}
		  
	}
}
.faqs-box{
	ul{
	li{
		margin-bottom: 6px;
	}
}
}
#sticky-isi { 
    position: fixed; 
    height:25%;
    z-index:1000;
    overflow-y: hidden;
    bottom: 0;
    right:0px;
    left:0px;
	box-shadow: 0px 0px 60px rgba(0,0,0,.7);
    //max-width: 80em;
    //max-width: 78em;
    width: 78em;
    max-width: 100%;
    margin: 0 auto;
    &.isi-section {
    	padding-top: 10px;
		
    	.sIND {
    		position:relative;

    		.isi-btn {
	    		position:absolute;
	    		right:0;
	    		text-decoration: none;
	    		color: $primary-color;
	    		font-size: 28px;
	    	}
    	}

    	&.mob_isi{  
        	max-height:75%;
        	height:auto;
        	padding-bottom: 0;
        	max-width: auto;
    		margin: auto;

        	h5{
        		margin-bottom: 0;
        	}

        	.content_box {
	          padding-top: 10px;
	          height: 75px;
	        }
		
	        .s_header{
	            font-size: 1.1em;
	            position:relative;
				
	            &:after{
	            content: "+";
	            font-size: 1.5em;
	            position: absolute;
	            right: 2px;
	            top:0;
	            color: $primary-color;
	            }
	            &.isiOn:after  {
	               content:"-" ;
	            }  
	            h5{
				color:#07545B;	
				}    
	         }

	        .sISI{ 
	        	overflow-y: hidden;
	        	border-top: 1px solid #999;
	        	padding-top: 5px;
	        	

	            &.On{
	                height:70%;
	                overflow-y: hidden;
	                
	                .content_box{
	                    height:40vh;
	                    overflow-y: auto;
	                }
	            }
	        }

	        .sIND{
	        	overflow-y: hidden;
	        	
	        	margin-bottom: 10px;

	            &.On{
	                height:79%;
	                overflow-y: hidden;
	                
	                .content_box{
	                    height:27vh;
	                    overflow-y: auto;
						
	                }
	            }
	        }


        }
    }
}
.page-ISI {
	
	 h5 {
	 	display: none;
	 }
	 h6{
	 	line-height: 1.1em;
		font-size: 24px;

		sup{
			top: -0.5em;
		}
	 }
}

/* ====================================================
    Footer Section
   ==================================================== */

footer {
	font-size: 13px;
	padding-top: 40px;
	padding-bottom: 40px;

	ul {
		list-style:none;
		margin:0;

		li{
			display: inline-block;
			border-right: 1px solid $black;
			padding-right: 10px;
			padding-left: 5px;

			&:first-child{
				padding-left: 0;
			}
			&:last-child{
				padding-right: 0;
				border-right: none;
			}

			a {


				&:hover{
					color: $secondary-color;
				}
			}

		}
	}

}

.mobile{
	display: none;
}
.desktop img{
	display: block;
	margin: auto;
	width: 100%;
}
.border-gray{
	width: 100%;
	height: 2px;
	background-color: #717C82;
	margin: 10px auto;
}
/* ====================================================
    Responsive Styling
   ==================================================== */

/* Small only */
@media screen and (max-width: 39.9375em) {
	.mobile{
		display: block;
		img{
			display: block;
			margin: auto;
		}
	}
	.desktop{
		display: none;
	}
	.logo {
		width: 120px;
	}

	.menu.align-center li {
		margin-bottom: 5px;
	}
	

	.reminderIcon .large-12 {
		margin-top: 30px;
	}

	.step-dose {

		.icon-steps {
			background-color: $primary-color;
			color:#fff;
			font-weight: bold;
			text-transform: uppercase;
			padding-top: 12px;
			padding-bottom: 12px;
			text-align: center;
			font-size: 15px;
			border-radius: 100px;

			p {
				margin-bottom: 0;
			}

			&.red {
				background-color: $secondary-color;
			}
		}
	}
}


/* ====================================================
    SweetAlert Modal
   ==================================================== */
.swal2-container.swal2-shown {
  background-color: rgba(0, 0, 0, .75) !important;
}

.swal2-popup {
  width: 48em !important;
  border-radius: 0px !important;
  background-color: #ffffff !important;
  padding-bottom: 35px !important;
  padding:1.25em 2em 2em !important;

}

.swal2-popup #swal2-content p{
  text-align:center !important;
}

.swal2-popup .swal2-title {
  color: $secondary-color !important;
    font-size: 40px !important;
    font-weight: 400 !important;
    margin: 10px 30px 20px !important;
    text-align:center !important;
	font-family: 'Arial', sans-serif;
}
.swal2-popup .swal2-content{
  font-size: 18px !important;
  color:#000 !important;
  line-height: 1.1em !important;
  margin: 0px 20px 0px !important;
  font-weight: normal !important;

}

.swal2-popup .swal2-actions {
  margin-top: 0px !important;
}

.swal2-popup .swal2-close, .swal2-popup .swal2-close:hover {
  -webkit-transform: none !important;
  transform: none !important;
  color: #969696 !important;
  font-weight:bold !important;
  box-shadow: none !important;
}

.swal2-popup .swal2-styled.swal2-confirm, .swal2-popup .swal2-styled.swal2-cancel {
  	border-radius: 30px !important;
  	font-size: 17px !important;
	background-color: $secondary-color !important;
	box-shadow: none !important;
	border: none !important;
	padding: 10px 20px 8px !important;
	text-transform:uppercase;

	&:after {
		content: '\25b6';
		margin-left: 10px;
	}
}


.swal2-popup .swal2-styled.swal2-cancel {
    background-color: #ccc !important;
    color: #666 !important;
    box-shadow: none !important;
    margin-left: 40px;
}


.swal2-popup .swal2-actions:not(.swal2-loading) .swal2-styled:hover {
  background-image: none !important;
}

@media screen and (max-width: 767px) {
  .swal2-popup {
    padding:1.25em 1em 3em !important;

    &.HCP_popup {
      background-position: top 40px left 35px;
    }
  }

  .swal2-popup .swal2-title { font-size: 24px !important; }

  .sweet-alert p { font-size:18px; }

  .swal2-popup .swal2-styled.swal2-confirm, .swal2-popup .swal2-styled.swal2-cancel{ width: 85%;}

  .swal2-popup .swal2-styled.swal2-cancel {
    margin-left:0px;
    margin-top: 20px;
  }

 }


 /**** Mega menu ***/
 .mega-menu {
 	//margin-top: 10px;
  .dropdown-pane {
    // width: 100%;
    // max-width: 85rem;
    margin: 0 auto !important;
    left: 0% !important;
    right: 0% !important;
    background-color: #008C98;
    border: 0 !important;
	width:calc(100vw - 0.55rem);
	position: absolute;
	left: calc(-50vw + 50%) !important;
	top: 78.4px !important;
    ul li {
    	text-transform: none;
    }
  }

  .menu {
    background: none;
  }

  > a::after {
    /*display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    margin-left: 10px;
    margin-top: 11%;*/
  }
  a.menu-parent {

  }

.dropdown-pane{
  	// &::after {
	// content: "";
    // display: block;
    // position: absolute;
    // top: -12px;
    // width: 0;
    // height: 0;
    // border-style: solid;
    // border-width: 0 12px 12px 12px;
    // border-color: transparent transparent rgba(0, 140, 152, 0.85) transparent;
  	// }
  }
.dropdown-pane{
	.arrow{
	content: "";
    display: block;
    position: absolute;
    top: -12px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 12px 12px;
    border-color: transparent transparent #008C98 transparent;
	}
}



	// &.menu-about .dropdown-pane::after {
	// 	left: 550px;
	// }
	// &.menu-hiv .dropdown-pane::after {
	// 	left: 750px;
	// }
	// &.menu-imp .dropdown-pane::after {
	// 	left: 1150px;
	// }
	// @media screen and (max-width:1280px){
	// 	&.menu-about .dropdown-pane::after {
	// 		left: 420px;
	// 	}
	// 	&.menu-hiv .dropdown-pane::after {
	// 		left: 630px;
	// 	}
	// 	&.menu-imp .dropdown-pane::after {
	// 		left: 1000px;
	// 	}	
	// }
	/** hcp ones **/
	&.menu-act .dropdown-pane::after {
		left: 375px;
	}
	&.menu-saf .dropdown-pane::after {
		left: 480px;
	}
}

.hcp .mega-menu {
 	//margin-top: 16px;
 }

.hcp ul.dropdown.menu {
	li.hcp-menu {
		a {
			margin-top: 16px;
		}
		ul li a {
			margin-top: 0px;
		}
	}
}
.top-bar {
	padding: 0;
}
.top-bar, .top-bar ul {
	background: none;
	text-transform: uppercase;
	text-align: center;
	li {
		a {
			padding: 0.7rem 2.8rem !important;
		}
		.dropdown-pane {
			ul.menu li {
				a {
					padding: 0rem 1rem !important;
				}
			}
		}
	}
	&.menu {
		.menu-text {
    		padding: 0!important;
    		a {
    			padding: 0 2.8rem 0 4.2rem !important;
    			@media screen and (min-width: 1024px) and (max-width: 1166px) {
			    	//padding-right: 0.225rem !important;
			    	padding-left: 0.225rem !important;
			    }
    			img.logo {
    				max-width: 150px;
    			}
    		}

    	}

    	&.dropdown {
    		li.patient-menu  {
    			a {
	    			margin-top: 10px;
		    		@media screen and (min-width: 1024px) and (max-width: 1166px) {
		    			//font-size: 17px;
		    			padding: 0.7rem 2.4rem !important;
		    		}
		    		ul li a {
		    			@media screen and (min-width: 1024px) and (max-width: 1166px) {
		    				font-size: 16px;
		    			}
		    		}
	    		}
	    		ul li a {
	    			margin-top: 0;
	    		}
    		}	
    	}
    	
	}
}

a.uline-fix-rball {
	display: inline-block;
	border-bottom: 1.5px solid $primary-color;
	line-height: .73em;
	text-decoration: none;

	&:hover {
		border-bottom-color: $secondary-color;
	}
}
